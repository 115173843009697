<template>
  <div class="PaperProve">
    <div class="fluid container">
      <div class="commonWhiteBg low">
        <a-divider class="topTitle">论文认证系统</a-divider>
        <a-row class="collapse_ul">
            <a-col class="ul_l" :md="24" :lg="24" :xl="24">
              <h3>论文认证政策背景及必要性说明</h3>
              <h4>什么是论文认证</h4>
              <p>论文认证是教育电子认证与教育信息化应用的集成与融合，利用互联网、云计算、大数据等现代技术，结合信用技术，按照国家实施信息安全、个人诚信体系建设和科研诚信体系建设的要求，提供安全、统一、有序的科研论文电子认证服务。论文认证项目是为满足快速发展的教育管理信息化，使用创新的电子认证模式。</p>
              <p>具体地说，论文认证是指教育和科研机构职称评定需要公开发表论文作为申请条件，那么就需要对于这些发表论文的审核和认证。为了有效地管理科研论文，评价论文水平的高低，并避免学术不端现象，教育机构和科研管理部门对高校教师、科研人员及学生发表在不同级别期刊上的论文进行真实性验证的过程，称为论文认证。</p>
              <h4>政策背景说明</h4>
              <p>2018年5月，中共中央办公厅、国务院办公厅印发《关于进一步加强科研诚信建设的若干意见》（厅字〔2018〕23号），要求坚持坚持无禁区、全覆盖、零容忍，严肃查处违背科研诚信要求的行为。</p>
              <p>2018年11月，包括教育部在内的41个部门联合印发《关于对科研领域相关失信责任主体实施联合惩戒的合作备忘录》（发改财金〔2018〕1600号），对科研领域严重失信行为提出了43项联合惩戒措施，明确规定由科技部通过全国信用信息共享平台定期向签署备忘录的相关部门提供科研领域联合惩戒对象的相关信息，同时在“信用中国”网站、科技部政府网站、国家企业信用信息公示系统向社会公布。</p>
              <h4>必要性说明</h4>
              <p>欧美发达国家对于学术论文诚信和预防作弊，有着极其严格的检查和认证制度。一旦相似度超过标准，就有可能被判为抄袭，造假或学术不端，后果很严重。当前，国内论文抄袭、造假、学术不端的案件、有违科研诚信要求的行为时有发生，严重破坏学术规范、损害教育公平。我国尚未建立对论文的诚信评价体系，以至实施诚信激励和失信惩戒等缺乏基础依据。论文认证是高校和科研机构尤其是一线科研人员的关切和需求。</p>
              <p>通过论文认证项目的实施，实现学术不端行为信息公开查询，符合国家知识产权保护政策的要求，是参与构建预防、惩治、保障三位一体的科研诚信体系的有效举措之一。</p>
              <h3>论文认证信息化服务及社会价值</h3>
              <a-col :md="24" :lg="24" :xl="12">
                <h4>信息化服务</h4>
                <ul>
                  <li>论文数据库（可提供相似性检测服务、AI检索）</li>
                  <li>论文认证申报系统（DOI、CA认证）</li>
                  <li>论文认证报告及证书（陈述事实）</li>
                  <li>区块链知识产权保护（确权、可追溯、可交易）</li>
                </ul>
              </a-col>
              <a-col :md="24" :lg="24" :xl="12">
                <h4>社会价值</h4>
                <ul>
                  <li>提高学术信用的诚信水平：公平、公开、公正</li>
                  <li>提供平台为论文作者提供优质低成本的支持与服务</li>
                  <li>提供知识产权保护服务</li>
                  <li>推动教育信息化2.0行动计划政策落实</li>
                </ul>
              </a-col>
            </a-col>
            <a-col class="ul_l" :md="24" :lg="24" :xl="24">
              <h3> 论文认证认定流程</h3>
              <img src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/PaperProve.png" alt="">
            </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaperProve'
}
</script>

<style lang='scss' scoped>
.PaperProve {
  background: url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/blueBg.svg') repeat-x;
  background-size: auto 224px;
  padding-top: 56px;
}
.low {
  padding: 72px 56px 56px;
  .topTitle {
    color: rgba(0, 18, 22, 0.65);
    font-size: 32px;
  }
  .topTitle::before,
  .topTitle::after{
    top: 0;
  }
  .collapse_ul{
    padding: 32px;
    box-shadow: 0 0 16px rgba(0, 109, 117, 0.08);
    border-radius: 8px 0 0 8px;
    .ul_l{
      h3{
        font-size: 16px;
        font-weight: bold;
        line-height: 30px;
        padding-bottom: 20px;
        padding-left: 20px;
        position: relative;
        margin-top: 50px;
      }
      h3::before{
        content: '';
        width: 6px;
        height: 24px;
        border-radius: 2px;
        background: #007DFF;
        position: absolute;
        left: 0;
        top: 3px;
      }
      h4{
        font-size: 14px;
        font-weight: bold;
        line-height: 26px;
        text-indent: 2em;
      }
      p{
        color: rgba(0, 18, 22, 0.65);
        text-indent: 2em;
        padding: 10px 0;
        line-height:1.769230769230769;
      }
      ul>li{
        color: rgba(0, 18, 22, 0.65);
        text-indent: 2em;
        padding: 10px 0;
        line-height:1.769230769230769;
        position: relative;
      }
      ul>li::before{
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: rgba(0, 18, 22, 0.65);
        position: absolute;
        left: 10px;
        top: 20px;
      }
      img{
        display: block;
        width: 600px;
        margin: 20px auto;
        max-width: 100%;
      }
    }
    .ul_l:nth-child(1){
      h3:nth-child(1){
        margin-top: 0;
      }
    }
    .ul_r{
      img{
        width: 100%;
        padding: 50px 0 50px 30px;
      }
    }
  }
}

@media screen and (min-width: 1px) and (max-width: 767px) {
  .PaperProve{padding-top: 24px;}
  .low {
    padding: 24px 16px 16px;
    .topTitle {
      color: rgba(0, 18, 22, 0.65);
      font-size: 24px;
    }
    .collapse_ul{
      .ul_r{
        img{
          padding: 50px 0;
        }
      }
    }
  }
}
</style>
